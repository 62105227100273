@keyframes emphasize {
  0% {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.8);
  }
  100% {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
}
@keyframes rerenderOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  text-decoration: none;
  transition: all 0.2s ease-out;
  padding: 0;
  margin: 0;
  font-family: monospace
}

::-webkit-scrollbar {
  display: none;
}

html {
  overflow: hidden;
}

.titleBar {
  -webkit-app-select: none;
  -webkit-app-region: drag;
  position: absolute;
  width: 100%;
  height: 32px;
  z-index: 5000;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  background: none;
  border: none;
  width: 100%;
  font-size: 14px;
  border-radius: 0px;
}
input:focus {
  outline: none;
}
input:placeholder-shown {
  width: 100%;
  text-overflow: ellipsis;
}

#header {
  z-index: 1000;
  height: 20vw;
  width: 100vw;
  position: fixed;
  top: 0;
  display: none;
}

.hiddenElement {
  opacity: 0;
}
.hiddenElement.selected {
  opacity: 1;
}
.mainContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.clickable:hover {
  cursor: pointer;
}
.bold {
  font-weight: 900;
}

#App {
  text-align: center;
  height: 100dvh;
}

#logoContainer {
  z-index: 1000;
  position: absolute;
  top: 26px;
  left: calc(50% - 48px);
  opacity: 0.25;
  display: flex;
  align-items: center;
}
#logoContainer.full:not(.executed) {
  top: calc(25% - 54px);
  left: 0px;
  z-index: 2000;
  pointer-events: none;
}
/* #logoContainer img:nth-child(1), #logoContainer img:nth-child(3) {
  height: 0px;
  opacity: 0;
}
#logoContainer.full:not(.executed) img:nth-child(1), #logoContainer.full:not(.executed) img:nth-child(3) {
  height: 32px;
  opacity: 1;
} */
#logoContainer img {
  height: 32px;
}
#logoContainer.full span {
  opacity: 0;
}
#logoContainer.full:not(.executed) img {
  height: calc(100vw / 3);
}

#introText, #pageNotFound {
  display: none;
}

.tooltip {
  font-size: 12px;
  opacity: 0.66;
}

#pageNotFoundBackToHome {
  transform: rotate(-90deg);
  height: 20px;
  width: 20px;
}

/* ------------------------------------------------ LOADING ANIMATION ------------------------------------------------ */

.loadingAnimationContainer {
  position: absolute;
  top: 45%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.loadingAnimation {
  height: 56px;
  width: 56px;
  animation-name: rerenderOpacity;
  animation-duration: 2s;
}
.loadingAnimation .fourDotsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: spinning;
  animation-duration: 1s;
  animation-iteration-count: 100;
}
.loadingAnimation .fourDotsContainer .dotsRow {
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.loadingAnimation .fourDotsContainer .dotsRow .dotsCell {
  height: 100%;
  width: 33%;
}
.loadingAnimation .fourDotsContainer .dotsRow .dotsCell.dot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingAnimation .fourDotsContainer .dotsRow .dotsCell.dot div {
  height: 50%;
  width: 50%;
  border-radius: 50%;
}

/* ------------------------------------------------ MAIN DISPLAY ------------------------------------------------ */

#kanjiDisplay.rerenderOpacity, #wordDisplay.rerenderOpacity {
  opacity: 0;
}
#mainDisplay {
  z-index: 800;
  padding-top: 88px;
  position: fixed;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#mainDisplay.hidden {
  opacity: 0;
}
#kanjiDisplay, #wordDisplay {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  animation-name: rerenderOpacity;
  animation-duration: 0.4s;
  opacity: 1;
}
#kanjiDisplayKanji {
  height: 20dvh;
  min-height: 20dvh;
  line-height: 115%;
  display: flex;
  align-items: flex-end;
  font-size: 18dvh;
  font-weight: 300;
}
#kanjiDisplayKanjiGhostLeft, #kanjiDisplayKanjiGhostRight {
  position: absolute;
  font-size: 10dvh;
  top: 16dvh;
  opacity: 0.25;
}
#kanjiDisplayKanjiGhostLeft {
  font-family: 'LXGW WenKai TC';
  left: 8.125%;
}
#kanjiDisplayKanjiGhostRight {
  font-family: 'Noto Sans TC';
  right: 8.125%;
}
#kanjiDisplayTranslation, #wordDisplayTranslation {
  height: fit-content;
  flex-shrink: 0;
  width: 100%;
  overflow: auto;
  font-size: 16px;
  font-weight: 100;
  padding: 0px 10%;
  white-space: nowrap;
}
#kanjiDisplayInfo, #wordDisplayInfo {
  display: flex;
  justify-content: center;
  width: 80%;
  padding-top: 8px;
  padding-bottom: 12px;
}
#kanjiDisplayInfo div, #wordDisplayInfo div {
  margin: 0px 10px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}
#wordDisplayWord, #wordDisplayWordJukujikun {
  width: 100%;
  height: 20dvh;
  min-height: 20dvh;
  font-size: 10dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#wordDisplayWord.smallFont, #wordDisplayWordJukujikun.smallFont {
  font-size: 5dvh;
  justify-content: center;
}
#wordDisplayWordElements {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 18px;
}
#wordDisplayWordKanjiOnly {
  display: flex;
}
.wordDisplayWordPrefix {
  opacity: 0.25;
  transition: none;
}
.wordDisplayWordElement {
  display: flex;
  flex-direction: column;
}
.wordDisplayWordElement span {
  white-space: nowrap;
}
.wordDisplayWordElementYomi {
  margin: 0px 12px;
  padding-top: 6px;
  font-size: 20px;
  line-height: 20px;
}
.wordDisplayWordElementYomi.jukujikun div {
  font-size: 14px;
  opacity: 0.5;
}

/* ------------------------------------------------ KANAS & KANJIS ------------------------------------------------ */

#kanjiDetails {
  height: calc(100dvh - 592px);
}
#wordDetails {
  height: calc(100dvh - 502px);
}
#kanjiDetails, #wordDetails {
  z-index: 900;
  width: 80%;
  font-size: 20px;
  margin: 0px 10% 0 10%;
  overflow-y: scroll;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}
#kanjiDetails.expanded, #wordDetails.expanded {
  height: auto;
}
#kanjiOrigin .kanjiElementKanji {
  opacity: 0.75;
}
#kanjiOrigin .kanjiReadings {
  opacity: 0.75;
}
/* #kanjiOrigin .kanjiElementKanji {
  font-family: 'Noto Serif TC';
} */
#wordDetailsSentences.bottomSpace {
  padding-bottom: 120px;
}
#wordDetailsKanjis, #wordDetailsSentences {
  width: 100%;
  text-align: left;
  padding-top: 32px;
}
#wordDetailsSentences {
  padding-top: 24px;
  padding-bottom: 70px;
  position: relative;
}
.kanjiDetailsSection:nth-child(1) {
  padding-top: 32px;
}
.kanjiDetailsSection:nth-child(2) {
  padding-bottom: 70px;
}
.kanjiDetailsSection:nth-child(1) .kanjiDetailsSubSection:nth-child(1) {
  margin-top: 0px;
}
.kanjiDetailsSubSection {
  margin-top: 24px;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.kanjiDetailsSubSection .tooltip, #wordDetailsSentences .tooltip {
  margin-top: 10px;
}
.yomiContainer {
  align-items: flex-start;
  justify-content: space-between;
  opacity: 0.5;
  margin-bottom: 10px;
  transition: opacity 0s;
}
.yomiContainer.extendable {
  opacity: 1;
}
.yomiContainer .fiveFirstElements .tooltip {
  padding-bottom: 10px;
}
.yomiHeader {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yomiHeader span {
  white-space: nowrap;
}
.yomiVariation {
  opacity: 0.5;
}
.yomiVariation span {
  font-size: 14px;
}
.yomiExtensionsHeader {
  margin-top: 4px;
  font-size: 16px;
}
.yomiExpander {
  height: 12px;
  opacity: 0.5;
  transform: rotate(180deg);
}
.yomiExpander.open {
  transform: rotate(0deg);
}
.yomiExamples {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-bottom: 10px;
  border-left: 1px solid;
}
.yomiExtensions {
  margin-top: -10px;
}
.wordElement {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 10px 0px;
}
.wordElementDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 40%;
  max-width: 80%;
}
.wordElementDetails.extended {
  max-width: 100%;
}
.wordElementTranslation {
  opacity: 0.5;
}
.wordElementGrammar {
  font-size: 12px;
  opacity: 0.8;
}
.wordElementTranslation, .wordElementGrammar {
  min-height: 19px;
  width: 100%;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.wordElementTranslation, .kanasReadingsHeader {
  font-size: 14px;
}
.yomiExamplesSubHeader {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 12px;
  opacity: 0.5;
}
.kanjiElement {
  height: 100%;
  display: flex;
  padding: 10px 0px;
  position: relative;
}
.kanjiElementKanji {
  width: 25%;
  line-height: 100%;
  text-align: left;
  font-size: 32px;
}
.kanjiElementDetails {
  width: 75%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: flex-end;
}
.kanjiElementDetails span {
  white-space: nowrap;
}
.kanjiElementDetails > div {
  width: 100%;
  height: 50%;
  line-height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.kanjiElementLabel {
  position: absolute;
  font-size: 12px;
  left: 45px;
  opacity: 0.66;
}
.kanjiElementLabel span {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.kanjiElementTranslation {
  margin-top: 2px;
  opacity: 0.5;
}
#wordLanguage {
  display: flex;
}
#wordLanguage span:nth-child(2) {
  opacity: 0.5;
  margin-left: 6px;
}
#kanjiPrecisions, #wordLanguage, #wordChineseLegacy, #wordPrecisions, #relatedKanji {
  padding-bottom: 24px;
}
#wordPrecisions, #relatedKanji {
  margin-bottom: 24px;
}
#kanjiPrecisions, #wordLanguage, #wordChineseLegacy, #wordPrecisions, #relatedWords {
  font-size: 14px;
  white-space: pre-wrap;
}
.kanasReadingsHeader {
  margin-bottom: 12px;
}
.fiveFirstElements {
  width: 100%;
}
.fiveFirstElements .moreThanFive {
  overflow: hidden;
}
.fiveFirstElements .moreThanFive:not(.open) .mainDisplayElementContainer {
  height: 0px;
}
.fiveFirstElements .moreThanFive:not(.open) .mainDisplaySentenceContainer {
  max-height: 0px;
  padding-bottom: 0px;
}
.mainDisplayElementContainer {
  height: 52px;
}
.mainDisplaySentenceContainer {
  overflow: hidden;
  max-height: 200px;
  padding-bottom: 12px;
}
#kanjiPrecisionsText, #wordPrecisionsText {
  opacity: 0.66;
}
.relatedWordsSubtitle {
  font-size: 12px;
  opacity: 0.66;
}
.sentencesElement {
  margin-top: 6px;
  font-size: 14px;
}
.sentence {
  display: flex;
}
.sentencePin {
  height: 16px;
  opacity: 0;
  margin-top: 4px;
  display: none;
}
.sentencePin.highlighted {
  opacity: 0.75 !important;
}
.sentence span {
  white-space: nowrap;
}
.sentencesElementTranslation {
  opacity: 0.5;
}
#wordDetailsPlus {
  z-index: 1000;
  position: absolute;
  top: calc(100dvh - 50px);
  height: calc(100dvh - 280px);
  width: 80%;
  font-size: 14px;
}
#wordDetailsPlus.expanded {
  top: calc(88px + 20dvh + 55px); 
}
#wordDetailsPlusIndicator {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#wordDetailsPlusIndicator img {
  height: 12px;
  position: absolute;
  top: 19px;
  left: calc(50% + 70px);
  transform: rotate(180deg);
}
#wordDetailsPlusIndicator img.open {
  transform: rotate(0deg);
}
#wordDetailsInflexions {
  height: calc(100% - 97px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  opacity: 0;
  padding-bottom: 42px;
}
#wordDetailsPlus.expanded #wordDetailsInflexions {
  opacity: 1;
}
.wordDetailsInflexionsMoodTitle {
  padding-top: 16px;
  padding-bottom: 12px;
  opacity: 0.8;
  font-size: 12px;
}
.wordDetailsInflexionsTense {
  padding: 10px 0px;
}
.wordDetailsInflexionsTenseTitle {
  padding-bottom: 6px;
  opacity: 0.5;
  font-size: 12px;
}
.wordDetailsInflexionsLine {
  display: flex;
  justify-content: space-between;
}
.wordDetailsInflexionsVerb {
  width: calc(50% - 16px);
  white-space: nowrap;
  direction: rtl;
}
.wordDetailsInflexionsVerb:first-child {
  opacity: 0.8;
}
.wordDetailsInflexionsLine span:nth-child(1) {
  text-align: right;
}
.wordDetailsInflexionsIndicator {
  opacity: 0.5;
}
.wordDetailsInflexionsLine span:nth-child(3) {
  text-align: left;
}
#kosoadoTable {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.kosoadoRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kosoadoRow div {
  width: 20%;
  padding: 5px;
}
.kosoadoIndicator {
  opacity: 0.5;
}

/* ------------------------------------------------ SIDE PANEL ------------------------------------------------ */

#sidePanel {
  display: flex;
  flex-direction: column;
  z-index: 1100;
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  right: -100dvw;
  justify-content: flex-start;
  padding: 6px 24px;
}
#sidePanel.electron {
  padding-top: 32px;
}
#sidePanel.open {
  right: 0vw;
}
#sidePanel.expanded {
  left: 0px;
  padding-top: calc(50dvh - 30px);
  background: none !important;
  transition: left 0.4s ease-out, width 0.4s ease-out, background 0s;
}
#sidePanel #wordsListSearchContainer.expanded {
  padding-left: 0px;
}
#sidePanel.expanded #searchResultsContainer {
  opacity: 0;
}

/* ------------------------------------------------ SIDE PANEL HEADER ------------------------------------------------ */

#wordsListHeader {
  position: relative;
  z-index: 1200;
  height: 0px;
  min-height: 0px;
  padding-bottom: 10px;
  opacity: 0;
  pointer-events: none;
}
#wordsListHeader.open {
  height: 80px;
  min-height: 80px;
  opacity: 1;
  pointer-events: all;
}
#wordsListFilterIcon {
  z-index: 1000;
  position: absolute;
  top: 22px;
  height: 36px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#wordsListFilterIcon img {
  transform: rotate(180deg);
}
#wordsListFilterIcon img.open {
  transform: rotate(0deg);
}
#wordsListFilterIcon {
  right: 0px;
  box-shadow: none;
}
#wordsListFilterIcon img.focused {
  animation-name: emphasize;
  animation-duration: 0.4s;
}
#wordsListFilterIcon img {
  height: 16px;
}
#wordsListFilterIcon.highlighted img {
  opacity: 1;
}
#wordsListFilters {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.wordsListHeaderRow {
  height: 20px;
  padding: 0vw 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wordsListHeaderRow.lowOpacity {
  opacity: 0;
}
#filtersTip {
  z-index: 1600;
  opacity: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 21px;
}
#filtersTip.open {
  opacity: 1;
}
#filtersTip div {
  width: fit-content;
}
#filtersTip.expanded div {
  padding-right: 5px;
}
#wordsListFilters img {
  height: 14px
}
#filtersIndicator {
  font-size: 12px;
  margin-top: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
#filtersIndicatorsElement, #filtersIndicatorRow {
  width: 90%;
  overflow-x: hidden;
}
#filtersIndicatorsEmpty {
  opacity: 0.5;
}
#filtersIndicatorRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
#filtersIndicatorRow img {
  height: 9px;
}
.filtersIndicatorsElement {
  padding: 0px 5px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.filtersIndicatorsElement span {
  text-overflow: ellipsis;
  text-align: right;
}
#wordsListSearchContainer {
  position: relative;
  padding-left: 52px;
}
#wordsListOpener, #searchSentencePin {
  z-index: 2200;
  height: 20px;
  position: absolute;
  top: 24px;
}
#wordsListOpener {
  left: -64px;
  transform: rotate(180deg);
}
#searchSentencePin {
  left: -24px;
}
#wordsListOpener.open {
  transform: rotate(0deg);
  left: 10px;
}
#wordsListSearch {
  position: relative;
  height: 56px;
  overflow-y: hidden;
}
#wordsListSearch input {
  padding-top: 22px;
  padding-right: 20px;
  font-size: 16px;
}
#wordsListSearch .icon {
  position: absolute;
  right: 0px;
  padding-top: 22px;
  padding-left: 16px;
}
#wordsListSearch .icon img {
  height: 16px;
  transition: none;
}
#wordsListSearch .icon img.close {
  height: 12px;
}

#wordsListFilterModalContainer {
  width: 100%;
  position: absolute;
  top: 73px;
  left: 0px;
  opacity: 0;
  pointer-events: none;
}
#wordsListFilterModalContainer.open {
  opacity: 1;
  pointer-events: all;
}

#wordsListFilterModal {
  opacity: 0;
  height: 0dvh;
  width: 100%;
  display: flex;
  overflow-y: hidden;
  z-index: 1500;
  justify-content: flex-end;
}
#wordsListFilterModal div {
  height: 100%;
  max-width: 40%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: scroll;
  padding-bottom: 160px;
}
#wordsListFilterModal.open {
  opacity: 1;
  height: calc(100dvh - 175px);
  padding-top: 15px;
}
#wordsListFilterModal.expanded.open {
  height: calc(50dvh - 25px);
}
#wordsListFilterModal span {
  flex-shrink: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}
#wordsListFilterModal div span:nth-child(1) {
  padding-top: 15px;
  padding-bottom: 15px;
}

/* ------------------------------------------------ SIDE PANEL LISTS ------------------------------------------------ */

#searchResultsContainer {
  display: flex;
  flex-direction: column;
}

.listIndicator {
  min-height: 30px;
  width: 100%;
  line-height: 30px;
  overflow-y: hidden;
  font-size: 12px;
  position: relative;
}
.listIndicator span {
  opacity: 0.9;
}
.listIndicator img {
  height: 12px;
  position: absolute;
  right: 20px;
  bottom: 8px;
}
.listIndicator.closed img {
  transform: rotate(180deg);
}
.noElementsFilteredIndicator {
  margin-top: 60%;
  opacity: 0.5;
  font-size: 12px;
  order: 10;
}
.wordsListList {
  height: calc(50dvh - 122px);
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  opacity: 1;
}
.wordsListList.extended {
  height: calc(100dvh - 200px);
}
.wordsListList.closed {
  height: calc(0dvh - 0px);
  overflow-y: hidden;
  padding: 0;
}
.wordsListList.hidden {
  opacity: 0;
}
.listElementContainer.importance2 {
  order: 1 !important;
}
.listElementContainer.importance1 {
  order: 2 !important;
}
#vocabularyList {
  margin-top: 33px;
  padding-bottom: 120px;
}
.listElementContainer {
  height: 54px;
  overflow: hidden;
  flex-shrink: 0;
  order: 3;
}
.listElement {
  padding: 0px 20px;
  height: 100%;
}
.listElement ,
.listElement div,
.listElement span,
.sentenceElementContainer,
.sentenceElementContainer div,
.sentenceElementContainer span {
  transition: none;
}
.wordElementRegularJapanese, .wordElementJukujikun {
  display: flex;
  white-space: nowrap;
  font-size: 14px;
}
.wordElementJukujikun {
  flex-direction: column;
  align-items: flex-start;
}
.wordElementRegularJapaneseMainCharacters {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
  line-height: 100%;
}
.wordElementRegularJapaneseMainCharacters:nth-child(1) {
  margin-left: 0px;
}
.wordElementRegularJapaneseMainCharacters:last-child {
  margin-right: 0px;
}
.wordElementJukujikunMainCharacters {
  display: flex;
  line-height: 100%;
}
.wordElementSecondaryCharacters {
  opacity: 0.6;
}

/* ------------------------------------------------ DISPLAY HISTORY ------------------------------------------------ */

#displayHistoryContainer {
  z-index: 2000;
  height: calc(100dvh - 35px);
  width: calc(100% - 16px);
  margin: 8px;
  position: absolute;
  top: 0px;
  left: -100%;
  overflow-y: scroll;
}
#displayHistoryContainer.open {
  left: 0%;
}
#displayHistoryContainer.electron {
  top: 40px;
  height: calc(100dvh - 56px);
}
#displayHistoryContainer img {
  height: 20px;
  position: fixed;
  top: 28px;
  right: calc(100vw - 0px);
  opacity: 0;
  z-index: 3000;
}
#displayHistoryContainer.electron img {
  top: 68px;
}
#displayHistoryContainer.open img {
  right: calc(24px);
  opacity: 0.5;
}
#historyIconContainer img {
  display: block;
  position: fixed;
  top: 24px;
  left: 22px;
  height: 32px;
  opacity: 0.5;
  z-index: 1000;
}
#historyIconContainer.electron img {
  top: 56px;
}
#displayHistory {
  padding-top: 80px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  z-index: 2000;
}

.historyElement {
  z-index: 2100;
  width: 100%;
  padding: 5px 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
.historyElement:nth-child(1) {
  border-bottom: none;
}
.historyElementMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  width: 100%;
  overflow-x: hidden;
}
.historyElementLabel {
  font-size: 12px;
  opacity: 0.33;
  margin: 5px 0px;
}
.historyElementTranslation {
  font-size: 12px;
  opacity: 0.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
.historyElementMain span {
  white-space: nowrap;
}

/* ------------------------------------------------ PINNED SENTENCE ------------------------------------------------ */

#searchSentenceContainer {
  opacity: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0px 30px 0px;
}
#searchSentenceContainer.open {
  z-index: 1000;
}
#searchSentenceHeader {
  width: 100%;
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  height: 20px;
}
#searchSentence {
  height: calc(0dvh - 0px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0px;
}
#searchSentence.open {
  height: calc(100dvh - 80px);
  padding-bottom: 80px;
}
.sentenceElementContainer {
  display: flex;
  max-width: 100%;
  padding: 0px 20px;
}
.sentenceElementUsedWord {
  min-height: 54px;
  width: 51px;
  flex-shrink: 0;
  white-space: nowrap;
  opacity: 0.5;
  font-size: 34px;
  writing-mode: vertical-lr;
  letter-spacing: 10px;
  padding-top: 10px;
}
.sentenceElementUsedNumber {
  width: 51px;
  font-size: 34px;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}
.sentenceElementUsedNumber span {
  padding-bottom: 8px;
}
.sentenceElementContainer.importance0 .sentenceElementUsedWord {
  opacity: 0.5;
}
.sentenceElementContainer.importance1 .sentenceElementUsedWord {
  opacity: 0.75;
}
.sentenceElementContainer.importance2 .sentenceElementUsedWord {
  opacity: 1;
}
.sentenceElement {
  width: calc(100% - 40.5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
  
}
.sentenceElementAmbiguity {
  width: 100%;
  text-align: right;
  padding-top: 5px;
  padding-right: 20px;
  font-weight: 600;
  opacity: 0.5;
}
.sentenceElementInfo {
  height: 54px;
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
}
.sentenceElementInfo.extended {
  height: fit-content;
  flex-direction: column;
  justify-content: center;
}
.sentenceElementInfo .wordElementGrammar {
  display: flex;
  justify-content: flex-end;
}
.sentenceElementInfo .wordElementGrammar .wordElementGrammarTense {
  display: flex;
  justify-content: flex-end;
}

/* ------------------------------------------------ DESKTOP VIEW ------------------------------------------------ */

@media (min-width:961px) {
  #logoContainer {
    flex-direction: column !important;
    top: auto !important;
    bottom: 20px !important;
    left: 18px !important;
    justify-content: center;
  }
  #logoContainer.full {
    height: 100dvh !important;
    left: 26px !important;
    bottom: 0px !important;
    opacity: 0.075 !important;
  }
  #logoContainer img {
    height: 48px !important;
  }
  #logoContainer.full img {
    height: 192px !important;
  }

  #introText, #pageNotFound {
    height: 100dvh;
    width: 100vw;
    text-align: justify;
    font-size: 18px;
    line-height: 30px;
    opacity: 0.75;
    position: absolute;
    display: flex;
    align-items: center;
  }
  #introText.lowOpacity {
    opacity: 0;
  }
  #introText p {
    width: 30%;
    margin-left: 242px;
  }
  #pageNotFound h1 {
    margin-left: 242px;
  }
  #pageNotFound p {
    margin-left: 42px;
  }
  #mainDisplay {
    padding-top: 60px;
    padding-left: 72px;
    width: 60%;
  }
  #kanjiDisplayKanji {
    height: 22dvh;
    min-height: 22dvh;
    font-size: 20dvh;
  }
  #kanjiDisplayKanjiGhostLeft {
    left: calc(20% + 43px);
  }
  #kanjiDisplayKanjiGhostRight {
    right: calc(20% - 43px);
  }
  #wordDisplayWord, #wordDisplayWordJukujikun {
    height: 22dvh;
    min-height: 22dvh;
    font-size: 11dvh;
  }
  #wordDisplayWord.smallFont, #wordDisplayWordJukujikun.smallFont {
    font-size: 8dvh;
  }
  #wordDisplayInfo {
    flex-direction: row;
  }
  #kanjiDisplayTranslation, #wordDisplayTranslation {
    font-size: 20px
  }
  #wordDetailsPlus {
    width: calc((100% - 72px) - ((100% - 72px) * (20/100)));
  }
  #wordDetailsPlus.expanded {
    top: calc(60px + 22dvh + 60px);
  }
  #sidePanel {
    right: 0vw;
    width: 40vw;
    background: none;
    padding: 26px;
  }
  #sidePanel.open:not(.expanded) {
    left: 60vw;
  }
  #sidePanel.expanded {
    left: 212px;
    width: calc(100vw - 424px);
  }
  #wordsListSearchContainer {
    padding-left: 0px;
  }
  #wordsListFilterModal.open {
    height: calc(100dvh - 195px);
  }
  #wordsListFilterModal.expanded {
    padding-right: 24px;
  }
  #wordsListFilterModal.expanded div {
    flex-grow: 0;
    margin-left: 40px;
  }
  .wordsListList {
    /* 156px is half of the height of the filters+search */
    height: calc(50dvh - 148px);
  }
  .wordsListList.extended {
    height: calc(100dvh - 220px);
  }
  #wordsListFilters {
    padding-left: 0;
  }
  #filtersTip.expanded {
    justify-content: flex-end;
  }
  #wordsListOpener {
    display: none;
  }
  #wordsListSearch img {
    right: 0px;
  }

  #displayHistoryContainer {
    width: 60%;
  }
  #displayHistoryContainer.open img {
    right: calc(40vw + 12px);
  }
  #historyIconContainer img {
    z-index: 1000;
    display: block;
    position: fixed;
    top: 40px;
    left: 24px;
    height: 32px;
    opacity: 0.5;
  }
  #historyIconContainer.electron img {
    top: 72px;
  }
  .historyElement {
    padding: 15px 0px;
    justify-content: flex-end;
  }
  #searchSentence.open {
    height: calc(100dvh - 100px);
  }
}

/* ------------------------------------------------ WIDE VIEW ------------------------------------------------ */

@media (min-width:1324px) {
  #introText p {
    width: 40%;
  }
  #mainDisplay {
    padding-left: 86px;
    width: 67%;
  }
  #mainDisplay.hidden {
    opacity: 0.5;
  }
  #kanjiDetails {
    height: calc(100dvh - 532px);
  }
  #wordDetails {
    height: calc(100dvh - 492px);
  }
  #kanjiDetails, #wordDetails {
    font-size: 28px;
    padding-bottom: 0px;
    flex-direction: row;
  }
  #kanjiDetails.expanded, #wordDetails.expanded {
    height: auto;
  }
  #wordDetailsPlus {
    height: calc(100dvh - 320px);
    width: calc((100% - 86px) - ((100% - 86px) * (20/100)));
  }
  #wordDetailsInflexions {
    height: calc(100% - 53px);
  }
  #wordDetailsKanjis, #wordDetailsSentences {
    width: 50%;
    overflow: scroll;
  }
  #wordDetailsKanjis {
    padding-right: 20px;
    padding-bottom: 70px;
  }
  #wordDetailsKanjis.bottomSpace {
    padding-bottom: 120px;
  }
  #wordDetailsSentences {
    padding-top: 32px;
    padding-left: 20px;
    text-align: right;
  }
  .kanjiDetailsSection {
    width: 50%;
    overflow-y: scroll;
    padding-bottom: 40px;
  }
  .kanjiDetailsSection:nth-child(2) {
    margin-top: 0px;
    padding-top: 32px;
  }
  .kanjiDetailsSubSection:nth-child(1) {
    margin-top: 0px;
  }
  #kunyomiExamples, #onyomiExamples, #irregularExamples, #jukujikunExamples {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  #kunyomiExamples .yomiContainer, #onyomiExamples .yomiContainer, #irregularExamples .yomiContainer {
    flex-direction: row-reverse;
  }
  #kunyomiExamples .yomiHeader, #onyomiExamples .yomiHeader, #irregularExamples .yomiHeader {
    flex-direction: row-reverse;
  }
  #kunyomiExamples .wordElement, #onyomiExamples .wordElement, #irregularExamples .wordElement, #jukujikunExamples .wordElement {
    flex-direction: row-reverse;
  }
  #kunyomiExamples .wordElementDetails div,
  #onyomiExamples .wordElementDetails div,
  #irregularExamples .wordElementDetails div,
  #jukujikunExamples .wordElementDetails div {
    text-align: left;
  }
  #kunyomiExamples .tooltip, #onyomiExamples .tooltip, #irregularExamples .tooltip, #jukujikunExamples .tooltip {
    text-align: right;
    font-weight: 900;
  }
  #jukujikunExamples .wordElementJukujikun {
    align-items: flex-end;
  }
  .kanjiDetailsKanjiElements, .yomiContainer, #jukujikunExamples .fiveFirstElements {
    width: 92.5%;
  }
  .yomiExamples {
    padding-left: 0px;
    padding-right: 10px;
    border-left: none;
    border-right: 1px solid;
  }
  .yomiExamplesSubHeader {
    text-align: right;
  }
  .yomiExtensionsHeader {
    font-size: 20px;
  }
  #selectorAndControls {
    margin-bottom: 20px;
  }
  .sentencesElement {
    padding-right: 0px;
    padding-left: 20px;
  }
  .sentencePin {
    display: block;
  }
  .sentencesElement:hover .sentencePin {
    opacity: 0.5;
  }
  .sentence {
    justify-content: flex-end;
  }
  #selector {
    justify-content: space-evenly;
    padding: 5px 0px;
  }
  #controls {
    padding: 0dvh 0px;
    justify-content: space-evenly;
  }
  #sidePanel {
    width: 33vw;
    background: none;
    padding: 26px;
  }
  #sidePanel.open:not(.expanded) {
    left: 67vw;
  }
  #displayHistoryContainer {
    width: 8%;
  }
  #displayHistoryContainer img {
    display: none;
  }
}

/* ------------------------------------------------ COLORS ------------------------------------------------ */

#App.light {
  --backgroundColor: rgba(255, 255, 255, 1);
  --primaryColor: rgba(0, 0, 0, 1);
  --secondaryColor: rgba(0, 0, 0, 0.75);
  --lightenedColor: rgba(0, 0, 0, 0.5);
  --paleColor: rgba(0, 0, 0, 0.15);
  --veryPaleColor: rgba(0, 0, 0, 0.075);
  --extremelyPaleColor: rgba(0, 0, 0, 0.025);
}

#App.dark {
  --backgroundColor: rgba(40, 45, 50, 1);
  --primaryColor: rgba(255, 255, 0, 1);
  --secondaryColor: rgba(255, 255, 0, 0.75);
  --lightenedColor: rgba(255, 255, 0, 0.5);
  --paleColor: rgba(255, 255, 0, 0.25);
  --veryPaleColor: rgba(255, 255, 0, 0.1);
}

#App {
  color: var(--primaryColor);
  background-color: var(--backgroundColor);
}

input {
  border-bottom: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  caret-color: var(--primaryColor);
}

input::placeholder {
  color: var(--lightenedColor);
}

input.highlighted::placeholder {
  color: var(--secondaryColor);
}

.loadingAnimation .fourDotsContainer .dotsRow .dotsCell.dot div {
  background-color: var(--lightenedColor);
}

#kanjisList, #vocabularyList, #searchSentence, .listIndicator {
  border: 1px solid var(--lightenedColor);
}

.listIndicator {
  border-bottom: none;
}

#kanjisList {
  border-top: none;
}

#searchSentence {
  border-top: none;
}

#kanjiDisplayTranslation, #wordDisplayTranslation {
  color: var(--primaryColor);
}

#kanjiDisplayKanji,
.wordDisplayWordElement span,
.wordDisplayWordElementYomi span {
  color: var(--primaryColor);
}

.wordDisplayWordElementYomi.highlighted {
  border-top: 1px solid var(--lightenedColor);
}

.yomiExamples {
  border-color: var(--paleColor);
}
.yomiExtensions {
  border-color: var(--veryPaleColor);
}

.sentencesElement .highlighted,
.kanjiElementDetails .highlighted,
.wordElementRegularJapaneseMainCharacters.highlighted {
  background-color: var(--paleColor);
}

.sentenceElementContainer.highlighted {
  background-color: var(--primaryColor);
  color: var(--backgroundColor);
}
.sentenceElementContainer.highlighted .sentenceElementUsedWord {
  opacity: 1;
}

#kanjiPrecisions, #relatedKanji, #wordPrecisions {
  border-bottom: 1px solid var(--lightenedColor);
}

#wordDetailsPlus {
  border-top: 1px solid var(--secondaryColor);
  background-color: var(--backgroundColor);
}

#kosoadoTable {
  border: 1px solid var(--lightenedColor);
}
.wordDetailsInflexionsMood:first-child {
  border-top: 1px solid var(--paleColor);
}
.wordDetailsInflexionsMood {
  border-bottom: 1px solid var(--paleColor);
}
.kosoadoRow {
  border-top: 1px solid var(--paleColor);
}

.kosoadoRow div:not(:last-child) {
  border-right: 1px solid var(--paleColor);
}

#wordsListFilterModal div span {
  border-right: 1px solid var(--primaryColor);
}

#kanjiDisplayInfo, #wordDisplayInfo {
  border-bottom: 2px solid var(--lightenedColor);
}

#sidePanel {
  background-color: var(--backgroundColor);
}

#wordsListFilterModalContainer {
  background-color: var(--backgroundColor);
}

.listElement.selected, #wordsListFilterModal span.selected, .kosoadoRow div.selected {
  background-color: var(--primaryColor);
  color: var(--backgroundColor);
}

.listElementContainer.importance2, .sentenceElementContainer.ambiguous {
  background-color: var(--paleColor);
}
.listElementContainer.importance1 {
  background-color: var(--veryPaleColor);
}

#selector {
  border-top: 1px solid var(--primaryColor);
  border-bottom: 1px solid var(--primaryColor);
}

.listElementContainer, .sentenceElementContainer, .historyElement {
  border-bottom: 1px solid var(--lightenedColor);
}

.sentenceElementInfo.extended .wordElement:not(:last-child) {
  border-bottom: 1px solid var(--lightenedColor);
}

#displayHistoryContainer {
  border: 1px solid var(--lightenedColor);
}

#displayHistoryContainer {
  background-color: var(--backgroundColor);
}

.historyElement.selected {
  background-color: var(--primaryColor);
  color: var(--backgroundColor);
}
